import React from 'react';
import {
  cannonStyled,
  NSH4,
  TWParagraph3,
  useCannonStyletron,
} from '../../../theme';
import DownloadPdfIcon from '../../../assets/download-pdf.svg';
import { ICannonSafe } from '../../../pages';
import { OutlinedButton } from '../../../atoms/Button';
import { Button } from '../../../atoms';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
import { useOrientation } from '../../../hooks/useOrientation';
import { IFileImage } from '../../../@types';

// STYLED COMPONENT

const MainContainer = cannonStyled(
  'div',
  ({ isMobile }: { isMobile: boolean }) => ({
    padding: 0,
    width: '100%',
    height: isMobile ? '750px' : 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    // background: 'blue'
  })
);
const ButtonsContainer = cannonStyled(
  'div',
  ({ isMobile }: { isMobile: boolean }) => ({
    marginTop: '40px',
    width: '100%',
  })
);

const ButtonContainer = cannonStyled('div', {
  margin: '5px',
});
interface Props {
  safe: ICannonSafe;
}

export const Component = ({ safe = {} as ICannonSafe }: Props) => {
  // HOOKS

  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();
  const { isRotated } = useOrientation();
  const API_URL = useAPIURL();

  const warningStyle = css({
    color: theme.cannonColors.secondaryOrangeLight,
    fontWeight: 'bold',
  });

  // STATE

  // EFFECTS

  // HANDLERS & FUNCTIONS
  function handleButtonClick(action: string) {
    if (safe.manual !== null) {
      const { manual = {} as IFileImage } = safe;
      const { url: manualUrl = '' } = manual;
      const path = API_URL + manualUrl;
      if (action === 'open') {
        window.open(path, '_blank');
      } else {
        let a: any = document.createElement('A');
        a.href = path;
        a.download = manualUrl;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } else {
      alert('There is not a manual for this safe');
    }
  }

  // RENDER
  const { displayName = '' } = safe;

  return (
    <MainContainer isMobile={isMobile}>
      <DownloadPdfIcon
        className={css({
          width: isMobile ? '58.5px' : '102.48px',
          height: isMobile ? '77.22px' : '135.13px',
        })}
      />
      <NSH4
        style={{
          width: '90%',
          padding: '12px',
          fontSize: isMobile ? '25px' : '36px',
        }}
        align='center'
        weight='bold'
        color={theme.cannonColors.primaryBlue}
      >
        Download your {displayName} User Manual
      </NSH4>
      <TWParagraph3
        style={{ width: '80%', fontSize: isMobile ? '17px' : '20px' }}
        align='center'
        weight={400}
        color={theme.cannonColors.secondarySilverDark}
      >
        Be sure to{' '}
        <a href='/register-your-safe' className={warningStyle}>
          REGISTER
        </a>{' '}
        your safe to activate your warranty and to expedite any customer service
        needs you may have. Visit our Cannon Safe{' '}
        <a href='/warranty' className={warningStyle}>
          Warranty
        </a>{' '}
        page for information about our Lifetime Replacement Promise
      </TWParagraph3>
      <ButtonsContainer isMobile={isMobile}>
        <Button.RoundedButton
          size='mini'
          color='primary'
          onClick={() => handleButtonClick('open')}
        >
          Open manual
        </Button.RoundedButton>
      </ButtonsContainer>
    </MainContainer>
  );
};

export default Component;
