import React from 'react';
import { LayoutProvider, Wrapper } from '../components/layout';
import { ResponsiveContainer } from '../atoms/Container';
import UserManualDownload from '../components/sections/UserManualDownload';
import { cannonStyled } from '../theme';
import { ICannonSafe } from '../pages/index';
import { CannonBreadcrumbs } from '../atoms';
import { useTableOrMobile } from '../hooks';
import IPage from '../@types/IPage';

// STYLED COMPONENTS

const MainWrapper = cannonStyled(Wrapper, (props: { isMobile: boolean }) => ({
  display: 'flex',
  maxWidth: '1280px',
  width: props.isMobile ? '95%' : '90%',
  maxHeight: '1000px',
  justifyContent: 'center',
  padding: props.isMobile ? '' : '20px 50px',
  marginLeft: 'auto',
  marginRight: 'auto',
  overflowX: 'hidden',
  textAlign: 'center',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  MsOverflowStyle: 'none',
  scrollbarWidth: 'none',
}));

const BreadCrumbsContainer = cannonStyled(
  'div',
  (props: { isMobile: boolean }) => ({
    position: 'absolute',
    top: props.isMobile ? '10px' : '30px',
    left: props.isMobile ? '20px' : '40px',
    width: props.isMobile ? '80vw' : '100%',
    textAlign: 'left',
  })
);

const DownloadManualPage = (
  props: IPage<{
    safe: ICannonSafe;
  }>
) => {
  const data = props.pageContext.safe;
  const [isMobile] = useTableOrMobile();

  let titleExt = data.slug 
  ? data.slug.split('-').join(' ')
  : '';

  return (
    <LayoutProvider seo locationSearch={props.location.search} titleExt={titleExt}>
      <MainWrapper isMobile={isMobile}>
        <BreadCrumbsContainer isMobile={isMobile}>
          <CannonBreadcrumbs
            pages={[
              { title: 'Home', href: '/' },
              { title: 'Support', href: '' },
              { title: 'Manuals', href: `/user-manuals` },
              { title: data.displayName, href: `/user-manual/${data.slug}` },
            ]}
          />
        </BreadCrumbsContainer>
        <ResponsiveContainer>
          <UserManualDownload safe={data} />
        </ResponsiveContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default DownloadManualPage;
